import { useState, useEffect } from 'react'

export const useIsInSection = (sectionIndex, sectionLength) => {
	const [isVisible, setIsVisible] = useState(null)

	useEffect(() => {
		if (sectionIndex === sectionLength) {
			setIsVisible(true)
		} else {
			setIsVisible(false)
		}
	}, [sectionIndex, sectionLength])

	return isVisible
}

export const useMousePosition = () => {
	const [mousePosition, setMousePosition] = useState({ x: null, y: null })
	useEffect(() => {
		const mousemove = (ev) => {
			setMousePosition({ x: ev.clientX, y: ev.clientY })
		}
		window.addEventListener('mousemove', mousemove)

		return () => {
			window.removeEventListener('mousemove', mousemove)
		}
	}, [])

	return mousePosition
}

export const useSwipeX = (ref, touchevent) => {
	const [mousePosition, setMousePosition] = useState({
		left: null,
		right: null,
	})
	useEffect(() => {
		const target = ref.current
		const events =
			touchevent === 'click'
				? ['mousedown', 'mouseup']
				: ['touchstart', 'touchend']
		const mousedown = (e) => {
			const oldX =
				touchevent === 'click' ? e.clientX : e.changedTouches[0].clientX
			const mouseup = (e) => {
				const newX =
					touchevent === 'click' ? e.clientX : e.changedTouches[0].clientX
				if (newX - oldX > 75) {
					setMousePosition({ left: true, right: false })
				} else if (newX - oldX < -75) {
					setMousePosition({ left: false, right: true })
				}

				window.removeEventListener(events[1], mouseup)
			}
			window.addEventListener(events[1], mouseup)
		}

		target.addEventListener(events[0], mousedown)

		return () => {
			target.removeEventListener(events[0], mousedown)
		}
	}, [ref, touchevent])

	return mousePosition
}

export const useLoader = () => {
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		const load = () => {
			setIsLoaded(true)
		}

		if (document.readyState === 'complete') {
			load()
		} else {
			window.addEventListener('load', load)
		}

		return () => window.removeEventListener('load', load)
	}, [])

	return isLoaded
}
