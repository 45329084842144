import { ReactComponent as SidegroundPath } from '../../../../assets/SidegroundPath.svg'
import { ReactComponent as SidegroundLogo } from '../../../../assets/SidegroundLogo.svg'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SidegroundImg = ({ className, isDraggedMe, turnToWhite, isMobile }) => {
	return (
		<div className={className}>
			<SidegroundLogo
				className={`logo${isDraggedMe || !isMobile ? ' appear' : ' hide'}`}
			/>
			{isMobile ? (
				<div
					className={`text ${turnToWhite ? 'blue' : 'active'}${
						isDraggedMe ? ' hide' : ' appear'
					}`}
				>
					<FontAwesomeIcon icon={'arrow-up'} />
					<span>Drag Me!</span>
				</div>
			) : (
				<></>
			)}
			<SidegroundPath className="path" />
		</div>
	)
}

export default SidegroundImg
