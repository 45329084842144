import { ReactComponent as VWLogo } from '../../assets/VW-Logo.svg'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './Header'
import Nav from './Nav'
import './index.scss'
import { useEffect, useState } from 'react'
import SmallNav from './SmallNav'
import { useRef } from 'react'
import Sideground from './Sideground'
import MobileNavigator from './MobileNavigator'
import { useMousePosition } from '../../CustomHook'
import Default from '../../assets/cursor/default.png'
import Onhover from '../../assets/cursor/onhover.png'
import Onclick from '../../assets/cursor/onclick.png'
import OndragBlue from '../../assets/cursor/drag-blue.png'
import OndragWhite from '../../assets/cursor/drag-white.png'

export default function Layout({
	navigation,
	turnToWhite,
	sectionIndex,
	setSectionIndex,
	activePath,
	setActivePath,
	setTurnToWhite,
	touchEvent,
	isMobile,
	hidden,
	socials,
	setIsMoving,
	isMoving,
	sectionLength,
	setIsClickNewPage,
}) {
	const sideground = useRef()
	const [isTouchSmNav, setIsTouchSmNav] = useState(false)
	const [movementX, setMovementX] = useState('')

	// Moving cursor
	const cursorProp = useRef()
	const [isClick, setIsClick] = useState(false)
	const [isHovering, setIsHovering] = useState(false)
	const [isHideProp, setIsHideProp] = useState(false)
	const mousePosition = useMousePosition()
	const location = useLocation()
	useEffect(() => {
		const mousedown = (e) => {
			const target = e.target
			cursorProp.current.style.top = `${e.clientY + 8}px`
			cursorProp.current.style.left = `${e.clientX + 7}px`
			target.style.cursor = `url(${Onclick}), auto`
			setIsClick(true)
			const mouseup = () => {
				setTimeout(() => {
					target.style.removeProperty('cursor')
					setIsClick(false)
					window.removeEventListener('mouseup', mouseup)
				}, 800)
			}
			window.addEventListener('mouseup', mouseup)
		}

		if (!isMobile) {
			document.body.style.cursor = `url(${Default}), auto`
			window.addEventListener('mousedown', mousedown)
			return () => {
				window.removeEventListener('mousedown', mousedown)
			}
		}
	}, [isMobile])

	useEffect(() => {
		const path = document.querySelector('#Path_92')
		path.style.cursor = `url(${OndragBlue}), auto`
		const mouseenter = (e) => {
			const target = e.target
			const hoverTagNames = ['button', 'a']
			if (hoverTagNames.includes(target.tagName.toLowerCase())) {
				target.style.cursor = `url(${Onhover}), auto`
				setIsHovering(true)
				setIsHideProp(false)
			} else if (target === path || target.closest('.puller')) {
				setIsHideProp(true)
			} else {
				setIsHideProp(false)
			}
		}

		const mouseleave = (e) => {
			const target = e.target
			const hoverTagNames = ['button', 'a']
			if (hoverTagNames.includes(target.tagName.toLowerCase())) {
				target.style.cursor = `url(${Default}), auto`
				setIsHovering(false)
			} else if (target === path || target.closest('.puller')) {
				setIsHideProp(false)
			} else {
				setIsHideProp(false)
			}
		}

		const tagA = document.querySelectorAll('a')
		const tagButton = document.querySelectorAll('button')
		const pullerBoxes = document.querySelectorAll('.puller')
		tagA.forEach((a) => {
			a.addEventListener('mouseenter', mouseenter)
			a.addEventListener('mouseleave', mouseleave)
		})
		tagButton.forEach((buton) => {
			buton.addEventListener('mouseenter', mouseenter)
			buton.addEventListener('mouseleave', mouseleave)
		})
		pullerBoxes.forEach((puller) => {
			puller.addEventListener('mouseenter', mouseenter)
			puller.addEventListener('mouseleave', mouseleave)
		})
		path.addEventListener('mouseenter', mouseenter)
		path.addEventListener('mouseleave', mouseleave)
	}, [location.pathname])

	useEffect(() => {
		const path = document.querySelector('#Path_92')
		if (turnToWhite) {
			path.style.cursor = `url(${OndragWhite}), auto`
		} else {
			path.style.cursor = `url(${OndragBlue}), auto`
		}
	}, [turnToWhite])
	return (
		<>
			{isMobile ? (
				<></>
			) : (
				<div
					className={`cursor-prop${
						isHovering ? ' onhover' : isClick ? ' onclick' : ' default'
					}`}
					style={{
						top: mousePosition.y + 20 + 'px',
						left: mousePosition.x + 15 + 'px',
						opacity: isHideProp ? 0 : 1,
					}}
					ref={cursorProp}
				/>
			)}
			<a href="https://www.vwintertrade.com/">
				<VWLogo className="vw-logo" />
			</a>
			{!isMobile && (
				<Header
					sectionIndex={sectionIndex}
					navigation={navigation}
					backgroundColor={'#103056'}
					isMobile={isMobile}
					setIsClickNewPage={setIsClickNewPage}
				/>
			)}

			<Sideground
				ref={sideground}
				touchEvent={touchEvent}
				isTouchSmNav={isTouchSmNav}
				setIsTouchSmNav={setIsTouchSmNav}
				navigation={navigation}
				activePath={activePath}
				turnToWhite={turnToWhite}
				setTurnToWhite={setTurnToWhite}
				movementX={movementX}
				setMovementX={setMovementX}
				hidden={hidden}
				socials={socials}
				isMobile={isMobile}
				setIsClickNewPage={setIsClickNewPage}
			/>
			{isMobile ? (
				<>
					<SmallNav
						touchEvent={touchEvent}
						setIsTouchSmNav={setIsTouchSmNav}
						setTurnToWhite={setTurnToWhite}
						navigation={navigation}
						activePath={activePath}
						sectionIndex={sectionIndex}
						sideground={sideground}
						setMovementX={setMovementX}
					/>
					<MobileNavigator
						setIsMoving={setIsMoving}
						sectionIndex={sectionIndex}
						setSectionIndex={setSectionIndex}
						sectionLength={sectionLength}
						isMoving={isMoving}
					/>
				</>
			) : (
				<Nav
					sectionIndex={sectionIndex}
					navigation={navigation}
					activePath={activePath}
					setIsClickNewPage={setIsClickNewPage}
				/>
			)}

			<Outlet />
		</>
	)
}
