import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { useState } from 'react'
import Snowfall from 'react-snowfall'
import styled from 'styled-components'

const Canvas = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 1000000000000;
`

const Loader = styled.div`
	width: 100%;
	height: 100%;
	background-color: #fbfcfe;
	transition: 0.5s ease-in-out;
	opacity: ${(props) => (props.finished ? '1' : '0')};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #0032a1;
	font-size: 30px;

	.loader {
		width: 100px;
		height: auto;
		animation: rotate 1.5s linear infinite both;
		color: #0032a1;
		margin-bottom: 15px;
	}
`

const Loading = ({ isClickNewPage }) => {
	const [count, setCount] = useState(0)
	const [fade, setFade] = useState(true)
	const [finished, setFinished] = useState(false)
	const [point, setPoint] = useState(null)

	useEffect(() => {
		if (isClickNewPage) {
			setCount(200)
			setFade(false)
			setTimeout(() => setFinished(true), 500)
		} else {
			setTimeout(() => setFinished(false), 500)
			setTimeout(() => setCount(0), 700)
			setTimeout(() => setFade(true), 1000)
		}
	}, [isClickNewPage])

	useEffect(() => {
		if (point === null) {
			setPoint(0)
		}

		if (point === 0) {
			setInterval(
				() =>
					setPoint((prev) => {
						prev = prev + 1
						if (prev > 3) {
							return 1
						}
						return prev
					}),
				1000
			)
		}
	}, [point])

	return (
		<Canvas>
			<Loader finished={finished}>
				<FontAwesomeIcon className="loader" icon={'snowflake'} />
				Loading{'.'.repeat(point)}
			</Loader>
			<Snowfall
				color="#c5dcea"
				snowflakeCount={count}
				speed={[5, 30]}
				wind={[5, 30]}
				style={{
					opacity: fade ? 0 : 1,
					transition: 'opacity 1s ease',
				}}
			/>
		</Canvas>
	)
}

export default Loading
