import { ReactComponent as Logo } from '../../../../assets/snow-beer-logo.svg'
import VWLogo from '../../../../assets/Logo-white.png'
import {
	faFacebook,
	faTwitter,
	faYoutube,
	faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'
import { useRef } from 'react'
import { useEffect } from 'react'
import DelayedLink from '../../../DelayedLink'

const Modal = ({
	navigation,
	activePath,
	setMovementX,
	touchEvent,
	socials,
	setIsClickNewPage,
}) => {
	const location = useLocation()
	const cancel = useRef()

	useEffect(() => {
		const cancelModal = () => {
			setMovementX('0')
		}
		cancel.current.addEventListener(touchEvent, cancelModal)
	}, [touchEvent, setMovementX])

	useEffect(() => {
		setMovementX('')
	}, [location, setMovementX])
	return (
		<div className="navigate-modal">
			<FontAwesomeIcon className="cancel" icon={'x'} ref={cancel} />
			<Logo className="logo" />
			<div className="container">
				<ul className="navs">
					{navigation.map((nav, key) => {
						return (
							<li key={`sm-nav${key}`} className="nav">
								<DelayedLink
									activeclassname="active"
									to={nav.href}
									setIsClickNewPage={setIsClickNewPage}
								>
									{nav.name}
								</DelayedLink>
							</li>
						)
					})}
				</ul>
				<div className="end">
					<div className="social-medias">
						{socials.map((social, key) => (
							<a href={social.href} target={'_blank'} rel={'noreferrer'}>
								<FontAwesomeIcon icon={social.icon} />
							</a>
						))}
					</div>
					<div className="copyright">
						<span>
							{new Date().getFullYear()} © VW Inter Trade Co., Ltd. ALL RIGHTS
							RESERVED.
						</span>
						<img src={VWLogo} alt="" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Modal
