import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import './App.scss'
import './responsive.scss'
import 'animate.css/animate.min.css'
import { useState, useEffect, useRef } from 'react'
import Layout from './components/Layout'
import o9n from 'o9n'
import {
	faFacebook,
	faInstagram,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import Error404 from './components/NotFound'
import PleaseRotate from './components/PleaseRotate'
import Loading from './components/Loading'
import Terms from './components/Terms'
import { Suspense } from 'react'

const AgeCheck = React.lazy(() => import('./components/AgeCheck'))
const Home = React.lazy(() => import('./components/Home'))
const OurStory = React.lazy(() => import('./components/OurStory'))
const Products = React.lazy(() => import('./components/Products'))
const Experiences = React.lazy(() => import('./components/Experiences'))
const Citizen = React.lazy(() => import('./components/Citizen'))
const Campaign = React.lazy(() => import('./components/Campaign'))
const ContactUs = React.lazy(() => import('./components/ContactUs'))

const iconList = Object.keys(Icons)
	.filter((key) => key !== 'fas' && key !== 'prefix')
	.map((icon) => Icons[icon])

library.add(...iconList, faYoutube, faFacebook, faInstagram)

const timeStamp = () => {
	const savedTimestamp = localStorage.getItem('timestamp')
	if (savedTimestamp) {
		const oldTimeStamp = savedTimestamp
		const newTimeStamp = new Date().getTime()
		const dayDiff = Math.floor(
			(newTimeStamp - oldTimeStamp) / (1000 * 60 * 60 * 24)
		)
		if (dayDiff > 7) {
			localStorage.removeItem('timestamp')
			return false
		} else return true
	} else return false
}

function App() {
	const [isAllowed, setIsAllowed] = useState(timeStamp())
	const [turnToWhite, setTurnToWhite] = useState(false)

	const navigation = [
		{
			name: 'Home',
			href: '/',
			className: 'nav-item home',
			icon: 'home',
		},
		{
			name: 'Our Story',
			href: '/our-story',
			className: 'nav-item our-story',
			icon: 'address-card',
		},
		{
			name: 'Products',
			href: '/products',
			className: 'nav-item products',
			icon: 'wine-bottle',
		},
		{
			name: 'Experiences',
			href: '/experiences',
			className: 'nav-item experiences',
			icon: 'calendar',
		},
		{
			name: 'SnowCitizen',
			href: '/citizen',
			className: 'nav-item citizen',
			icon: 'hat-cowboy',
		},
		{
			name: 'Campaign',
			href: '/campaign',
			className: 'nav-item campaign',
			icon: 'snowflake',
		},
		{
			name: 'Contact Us',
			href: '/contact-us',
			className: 'nav-item contact-us',
			icon: 'envelope',
		},
	]

	const socials = [
		{
			icon: ['fab', 'facebook'],
			href: 'https://www.facebook.com/SnowThailandTH',
		},
		{
			icon: ['fab', 'instagram'],
			href: 'https://www.instagram.com/snowthailand.official/',
		},
		{
			icon: ['fab', 'youtube'],
			href: 'https://www.youtube.com/channel/UCyQHLIaKs0T1DwMHZewbvSA',
		},
	]

	// Listen for resize
	const [isMobile, setIsMobile] = useState(null)
	const [width, setWidth] = useState(window.innerWidth)
	useEffect(() => {
		const resize = () => {
			setWidth(window.innerWidth)
		}
		window.addEventListener('resize', resize)
	}, [])
	// Define Portable Device or Mobile?
	useEffect(() => {
		if (isMobile === null) {
			setIsMobile(
				window.matchMedia('(any-pointer:coarse)').matches || width < 1024
			)
		}
	}, [isMobile, width])

	const [sectionIndex, setSectionIndex] = useState(0)
	const [sectionLength, setSectionLength] = useState(0)
	const [isMoving, setIsMoving] = useState({ up: null, down: null })
	const [y, setY] = useState(false)
	const firstTime = useRef(true)

	// Get active path name
	const location = useLocation()
	const [activePath, setActivePath] = useState(location.pathname)
	useEffect(() => {
		const path = navigation.find((nav) => nav.href === location.pathname)
		setActivePath(path ? path.href : 'null')
		setSectionIndex(0)
	}, [location.pathname])

	// Detect wheel or swipe, up or down
	useEffect(() => {
		if (location.pathname !== '/terms') {
			const wheel = (e) => {
				e.stopImmediatePropagation()
				if (e.deltaY < 0) {
					setIsMoving((prev) => {
						return { ...prev, up: true }
					})
				} else {
					setIsMoving((prev) => {
						return { ...prev, down: true }
					})
				}
				setTimeout(
					() =>
						setIsMoving(() => {
							return { up: false, down: false }
						}),
					650
				)
			}

			const touchstart = (e) => {
				if (y) {
					return
				}
				e.stopPropagation()
				const oldY = e.targetTouches[0].clientY

				const touchend = (e) => {
					e.stopPropagation()
					const newY = e.changedTouches[0].clientY
					if (newY - oldY > 60) {
						setIsMoving((prev) => {
							return { ...prev, up: true }
						})
					} else if (newY - oldY < -60) {
						setIsMoving((prev) => {
							return { ...prev, down: true }
						})
					}

					window.removeEventListener('touchend', touchend)
					setTimeout(
						() =>
							setIsMoving(() => {
								return { up: false, down: false }
							}),
						650
					)
				}
				window.addEventListener('touchend', touchend)
			}

			window.addEventListener('wheel', wheel)
			window.addEventListener('touchstart', touchstart)

			return () => {
				window.removeEventListener('wheel', wheel)
				window.removeEventListener('touchstart', touchstart)
			}
		}
	}, [isMoving, y, location])

	// Switch section
	useEffect(() => {
		if (location.pathname !== '/terms') {
			const sectionsLength = {
				'/': 7,
				'/our-story': 3,
				'/products': 2,
				'/experiences': 3,
				'/citizen': 2,
				'/campaign': 3,
				'/contact-us': 2,
			}

			if (isMoving.up && !isMoving.down && firstTime.current) {
				firstTime.current = false
				setSectionIndex((prev) => {
					prev = prev - 1
					if (prev < 0) {
						return (prev = 0)
					}
					return prev
				})
				setTimeout(() => {
					firstTime.current = true
				}, 800)
			} else if (isMoving.down && !isMoving.up && firstTime.current) {
				firstTime.current = false
				setSectionIndex((prev) => {
					prev = prev + 1
					if (prev > sectionsLength[activePath] - 1) {
						return (prev = sectionsLength[activePath] - 1)
					}
					return prev
				})
				setTimeout(() => {
					firstTime.current = true
				}, 800)
			}
		}
	}, [isMoving, activePath, isMobile, location])

	// Define touch event
	const [touchEvent, setTouchEvent] = useState(null)
	useEffect(() => {
		setTouchEvent('ontouchstart' in window ? 'touchstart' : 'click')
	}, [])

	// Sideground hidden
	const [hidden, setHidden] = useState(null)

	// Detect orientation change and disable landscape screen in mobile
	const [isMobileHorizon, setIsMobileHorizon] = useState(null)
	const [isPortrait, setIsPortrait] = useState(null)
	useEffect(() => {
		const media = window.matchMedia('(orientation: landscape)')
		const change = (media) => {
			if (media.matches) {
				setIsPortrait(false)

				if (window.innerHeight < 500) {
					setIsMobileHorizon(true)
				} else {
					setIsMobileHorizon(false)
				}
			} else {
				setIsPortrait(true)
				setIsMobileHorizon(false)
			}
		}

		if (media.matches) {
			setIsPortrait(false)
			if (window.innerHeight < 500) {
				setIsMobileHorizon(true)
			} else {
				setIsMobileHorizon(false)
			}
		} else {
			setIsPortrait(true)
			setIsMobileHorizon(false)
		}

		media.addEventListener('change', change)

		return () => {
			media.removeEventListener('change', change)
		}
	}, [isMobile])

	// Temp Allow to terms page
	useEffect(() => {
		if (location.pathname === '/terms') {
			setIsAllowed(true)
		} else {
			setIsAllowed(timeStamp())
		}
	}, [location])

	// Change page animation
	const [isClickNewPage, setIsClickNewPage] = useState(false)
	useEffect(() => {
		if (isClickNewPage) {
			setTimeout(() => setIsClickNewPage(false), 1500)
		}
	}, [isClickNewPage])

	return (
		<>
			{isMobileHorizon ? (
				<PleaseRotate />
			) : (
				<>
					{!isAllowed ? (
						<AgeCheck
							isMobile={isMobile}
							isAllowed={isAllowed}
							setIsAllowed={setIsAllowed}
							setIsClickNewPage={setIsClickNewPage}
						/>
					) : (
						<>
							<Loading isClickNewPage={isClickNewPage} />
							<Routes>
								<Route
									path="/"
									element={
										<Layout
											sectionIndex={sectionIndex}
											setSectionIndex={setSectionIndex}
											turnToWhite={turnToWhite}
											setTurnToWhite={setTurnToWhite}
											navigation={navigation}
											activePath={activePath}
											touchEvent={touchEvent}
											isMobile={isMobile}
											hidden={hidden}
											setHidden={setHidden}
											socials={socials}
											setIsMoving={setIsMoving}
											isMoving={isMoving}
											sectionLength={sectionLength}
											setIsClickNewPage={setIsClickNewPage}
										/>
									}
								>
									<Route
										index
										element={
											<Suspense>
												<Home
													turnToWhite={turnToWhite}
													setTurnToWhite={setTurnToWhite}
													sectionIndex={sectionIndex}
													setSectionIndex={setSectionIndex}
													navigation={navigation}
													touchEvent={touchEvent}
													isMobile={isMobile}
													setHidden={setHidden}
													socials={socials}
													setSectionLength={setSectionLength}
													isPortrait={isPortrait}
													setIsClickNewPage={setIsClickNewPage}
													activePath={activePath}
												/>
											</Suspense>
										}
									/>
									<Route
										path="/our-story"
										element={
											<Suspense>
												<OurStory
													turnToWhite={turnToWhite}
													setTurnToWhite={setTurnToWhite}
													sectionIndex={sectionIndex}
													setSectionIndex={setSectionIndex}
													navigation={navigation}
													touchEvent={touchEvent}
													isMobile={isMobile}
													setHidden={setHidden}
													socials={socials}
													setSectionLength={setSectionLength}
													isPortrait={isPortrait}
													setIsClickNewPage={setIsClickNewPage}
													activePath={activePath}
												/>
											</Suspense>
										}
									/>
									<Route
										path="/products"
										element={
											<Suspense>
												<Products
													turnToWhite={turnToWhite}
													setTurnToWhite={setTurnToWhite}
													sectionIndex={sectionIndex}
													setSectionIndex={setSectionIndex}
													navigation={navigation}
													touchEvent={touchEvent}
													isMobile={isMobile}
													setHidden={setHidden}
													socials={socials}
													setSectionLength={setSectionLength}
													y={y}
													setY={setY}
													isPortrait={isPortrait}
													setIsClickNewPage={setIsClickNewPage}
													activePath={activePath}
												/>
											</Suspense>
										}
									/>
									<Route
										path="/experiences"
										element={
											<Suspense>
												<Experiences
													turnToWhite={turnToWhite}
													setTurnToWhite={setTurnToWhite}
													sectionIndex={sectionIndex}
													setSectionIndex={setSectionIndex}
													navigation={navigation}
													touchEvent={touchEvent}
													isMobile={isMobile}
													setHidden={setHidden}
													socials={socials}
													setSectionLength={setSectionLength}
													isPortrait={isPortrait}
													setIsClickNewPage={setIsClickNewPage}
													activePath={activePath}
												/>
											</Suspense>
										}
									/>
									<Route
										path="/citizen"
										element={
											<Suspense>
												<Citizen
													turnToWhite={turnToWhite}
													setTurnToWhite={setTurnToWhite}
													sectionIndex={sectionIndex}
													setSectionIndex={setSectionIndex}
													navigation={navigation}
													touchEvent={touchEvent}
													isMobile={isMobile}
													setHidden={setHidden}
													socials={socials}
													setSectionLength={setSectionLength}
													isPortrait={isPortrait}
													setIsClickNewPage={setIsClickNewPage}
													activePath={activePath}
												/>
											</Suspense>
										}
									/>
									<Route
										path="/campaign"
										element={
											<Suspense>
												<Campaign
													turnToWhite={turnToWhite}
													setTurnToWhite={setTurnToWhite}
													sectionIndex={sectionIndex}
													setSectionIndex={setSectionIndex}
													navigation={navigation}
													touchEvent={touchEvent}
													isMobile={isMobile}
													setHidden={setHidden}
													socials={socials}
													setSectionLength={setSectionLength}
													isPortrait={isPortrait}
													setIsClickNewPage={setIsClickNewPage}
													activePath={activePath}
												/>
											</Suspense>
										}
									/>
									<Route
										path="/contact-us"
										element={
											<Suspense>
												<ContactUs
													turnToWhite={turnToWhite}
													setTurnToWhite={setTurnToWhite}
													sectionIndex={sectionIndex}
													setSectionIndex={setSectionIndex}
													navigation={navigation}
													touchEvent={touchEvent}
													isMobile={isMobile}
													setHidden={setHidden}
													socials={socials}
													setSectionLength={setSectionLength}
													isPortrait={isPortrait}
													setIsClickNewPage={setIsClickNewPage}
													activePath={activePath}
												/>
											</Suspense>
										}
									/>
								</Route>
								<Route path="/terms" element={<Terms />} />
								<Route path="*" element={<Error404 />} />
							</Routes>
						</>
					)}
				</>
			)}
		</>
	)
}

export default App
