import './index.scss'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import DelayedLink from '../../DelayedLink'

export default function Header({
	navigation,
	backgroundColor,
	sectionIndex,
	setIsClickNewPage,
}) {
	const nav = useRef()
	useEffect(() => {
		if (sectionIndex !== 0) {
			nav.current.style.transform = 'translateY(-80px)'
		} else {
			nav.current.style.transform = 'translateY(0)'
		}
	}, [sectionIndex])

	return (
		<div className="fixed-nav" ref={nav}>
			<ul
				className="nav-container"
				style={{ backgroundColor: backgroundColor }}
			>
				{navigation.map((item, i) => {
					return (
						<li className="nav-item" key={i}>
							<DelayedLink to={item.href} setIsClickNewPage={setIsClickNewPage}>
								{item.name}
							</DelayedLink>
						</li>
					)
				})}
			</ul>
		</div>
	)
}
