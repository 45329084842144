import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Snowfall from 'react-snowfall'
import Logo from '../../assets/Logo-white.png'
import { ReactComponent as LogoHorizontal } from '../../assets/snow-logo-horizontal.svg'
import './index.scss'

const Error404 = () => {
	useEffect(() => {
		document.title = 'Snow Beer | 404 Page Not Found!'
	}, [])
	return (
		<section className="error">
			<div className="content">
				<div className="main">404</div>
				<LogoHorizontal className="logo" />
				<div className="text">
					The page you are looking for might have been removed
					<br />
					or never existed in the first place.
				</div>
				<Link to={'/'}>
					<button className="redirect">Redirect to Landing Page</button>
				</Link>
			</div>
			<span className="foot">
				<span>2022 © VW Inter Trade Co., Ltd. ALL RIGHTS RESERVED.</span>
				<img src={Logo} alt="" />
			</span>
			<Snowfall style={{ zIndex: -1 }} />
			<div className="filter" />
		</section>
	)
}

export default Error404
