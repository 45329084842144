import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'
import DelayedLink from '../../../DelayedLink'

const Menu = ({ item, isActive, setIsClickNewPage }) => {
	const [isHoverNavIcon, setIsHoverNavIcon] = useState(false)
	return (
		<DelayedLink to={item.href} setIsClickNewPage={setIsClickNewPage}>
			<div
				className={`${item.className} ${isActive ? 'active' : ''}`}
				onMouseEnter={() => setIsHoverNavIcon(true)}
				onMouseLeave={() => setIsHoverNavIcon(false)}
			>
				<FontAwesomeIcon
					icon={item.icon}
					style={{ opacity: isHoverNavIcon ? 0 : 1 }}
				/>
				<span style={{ opacity: isHoverNavIcon ? 1 : 0 }}>{item.name}</span>
			</div>
		</DelayedLink>
	)
}
export default Menu
