import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

export default function SmallNav({
	navigation,
	activePath,
	setMovementX,
	sideground,
}) {
	return (
		<div
			className="sm-nav"
			onTouchStart={() => {
				setMovementX('100%')
			}}
			onClick={() => {
				setMovementX('100%')
			}}
		>
			<FontAwesomeIcon
				icon={navigation.find((navi) => navi.href === activePath).icon}
			/>
		</div>
	)
}
