import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const DelayedLink = ({ to, children, setIsClickNewPage, ...props }) => {
	const location = useLocation()
	const navigate = useNavigate()

	const onClick = (e) => {
		e.preventDefault()
		setIsClickNewPage(true)
		setTimeout(() => navigate(to), 1500)
	}

	const match = to === location.pathname

	return (
		<Link
			to={to}
			{...props}
			{...(match ? { className: 'active' } : {})}
			onClick={onClick}
		>
			{children}
		</Link>
	)
}

export default DelayedLink
