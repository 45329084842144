import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import './index.scss'

const MobileNavigator = ({
	setIsMoving,
	sectionIndex,
	setSectionIndex,
	sectionLength,
}) => {
	const [isPressedUp, setIsPressedUp] = useState(false)
	const [isPressedDown, setIsPressedDown] = useState(false)
	const [isFirstSection, setIsFirstSection] = useState(false)
	const [isSectionLength, setIsSectionLength] = useState(false)

	useEffect(() => {
		if ((sectionIndex != null || sectionIndex !== undefined) && sectionLength) {
			if (sectionIndex === 0) {
				setIsFirstSection(true)
			} else {
				setIsFirstSection(false)
			}

			if (sectionIndex === sectionLength) {
				setIsSectionLength(true)
			} else {
				setIsSectionLength(false)
			}
		}
	}, [sectionIndex, sectionLength])

	useEffect(() => {
		if (isPressedUp) {
			setSectionIndex((prev) => prev - 1)
		} else if (isPressedDown) {
			setSectionIndex((prev) => prev + 1)
		}
	}, [isPressedUp, isPressedDown, setSectionIndex])

	useEffect(() => {
		if (isPressedUp || isPressedDown) {
			setTimeout(() => {
				setIsPressedUp(false)
				setIsPressedDown(false)
			}, 800)
		}
	}, [isPressedUp, isPressedDown])

	return (
		<div className="navigate">
			{isFirstSection ? (
				<></>
			) : (
				<div
					className={`arrow${isPressedUp ? ' press' : ''}`}
					onClick={() => setIsPressedUp(true)}
				>
					<FontAwesomeIcon icon="chevron-up" />
				</div>
			)}

			{isSectionLength ? (
				<></>
			) : (
				<div
					className={`arrow${isPressedDown ? ' press' : ''}`}
					onClick={() => setIsPressedDown(true)}
				>
					<FontAwesomeIcon icon="chevron-down" />
				</div>
			)}
		</div>
	)
}

export default MobileNavigator
