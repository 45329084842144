import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { ReactComponent as LogoHorizontal } from '../../assets/snow-logo-horizontal.svg'
import './index.scss'
export default function PleaseRotate() {
	const [highlight, setHighlight] = useState(false)

	useEffect(() => {
		if (highlight) {
			setTimeout(() => setHighlight(false), 1500)
		} else {
			setTimeout(() => setHighlight(true), 1500)
		}
	}, [highlight])
	return (
		<div className="pls-rotate">
			<LogoHorizontal alt={'Snow Beer'} />
			<h2>World's best selling beer</h2>
			<span className={`highlight ${highlight ? 'light' : 'unlight'}`}>
				Please Rotate Your Device
			</span>
			<FontAwesomeIcon className="rotate" icon={'snowflake'} />
		</div>
	)
}
