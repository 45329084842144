import { useEffect } from 'react'
import styled from 'styled-components'

const Terms = () => {
	useEffect(() => {
		document.body.style.overflowY = 'scroll'

		return () => {
			document.body.style.overflow = 'hidden'
		}
	}, [])

	const color = {
		primary: '#103056',
		secondary: ' #0032A1',
		text: '#C5DCEA',
		active: '#37E4FF',
		snow: '#FBFCFE',
	}

	const TermsAndCondition = styled.section`
		width: 100%;
		height: 100%;
		display: block;
		position: relative;
	`
	const Head = styled.div`
		width: 100%;
		height: calc(20vh + 20px);
		color: ${color.snow};
		background-color: ${color.secondary};
		display: flex;
		align-items: center;
		padding: 20px;
	`
	const Container = styled.div`
		width: 60%;
		margin: auto;

		@media screen and (max-width: 1366px) {
			width: 70%;
		}
		@media screen and (max-width: 1024px) {
			width: 80%;
		}
		@media screen and (max-width: 768px) {
			width: 85%;
		}
		@media screen and (max-width: 550px) {
			width: 90%;
			text-align: center;
		}
	`
	const HeadText = styled.h4`
		font-size: 100px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

		@media screen and (max-width: 1440px) {
			font-size: 70px;
		}

		@media screen and (max-width: 1024px) {
			font-size: 50px;
		}

		@media screen and (min-width: 1024px) and (orientation: portrait) {
			font-size: 100px;
		}

		@media screen and (max-width: 820px) {
			font-size: 70px;
		}

		@media screen and (max-width: 600px) {
			font-size: 45px;

			&::after {
				display: none;
			}
		}
	`
	const Body = styled.div`
		margin-top: 25px;
		text-align: center;
		color: ${color.snow};
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	`
	const Span = styled.span`
		font-size: 60px;
		margin: 10px 0 0 0;
		color: ${color.active};

		@media screen and (max-width: 1440px) {
			font-size: 50px;
		}

		@media screen and (max-width: 1024px) {
			font-size: 35px;
		}

		@media screen and (min-width: 1024px) and (orientation: portrait) {
			font-size: 60px;
		}

		@media screen and (max-width: 820px) {
			font-size: 55px;
		}

		@media screen and (max-width: 600px) {
			font-size: 35px;
		}
	`

	const Topic = styled.b`
		font-size: 45px;
		margin: 10px 0 0 0;
		color: ${color.snow};

		@media screen and (max-width: 1440px) {
			font-size: 35px;
		}

		@media screen and (max-width: 1024px) {
			font-size: 30px;
		}

		@media screen and (min-width: 1024px) and (orientation: portrait) {
			font-size: 45px;
		}

		@media screen and (max-width: 820px) {
			font-size: 40px;
		}

		@media screen and (max-width: 600px) {
			font-size: 28px;
		}
	`

	const Paragraph = styled.p`
		font-size: 30px;
		margin: 20px 0 40px 0;
		color: ${color.snow};

		@media screen and (max-width: 1440px) {
			font-size: 22px !important;
		}

		@media screen and (max-width: 1024px) {
			font-size: 35px !important;
		}

		@media screen and (max-width: 820px) {
			font-size: 30px !important;
		}

		@media screen and (max-width: 600px) {
			font-size: 18px !important;
		}
	`

	return (
		<TermsAndCondition>
			<Head>
				<Container>
					<HeadText>TERMS AND CONDITIONS</HeadText>
				</Container>
			</Head>
			<Container>
				<Body>
					<Span>DISCLAIMER</Span>
					<Topic>Scope and Applicability</Topic>
					<Paragraph>
						Welcome to this website (the “Site”), which is operated by VW Inter
						Trade LIMITED (“we“, “us” or “our”) to provide online access to
						information about us, and our products, services and the
						opportunities we provide (collectively, the “Service”). By accessing
						this Site and using the Service, you warrant and represent to us
						that you are above the relevant legal drinking age in your country,
						and agree to be legally bound by each of the terms and conditions
						set forth herein, as may be varied or amended from time to time (the
						“Terms And Conditions”), which shall take effect immediately on your
						first use of the Site. If you are not above the relevant legal
						drinking age in your country or do not agree to be legally bound by
						any of these Terms And Conditions, please do not access the Site
						and/or use the Service.
						<br />
						<br />
						Additional terms and conditions applicable to specific areas or
						sections of this Site or to particular content or transactions may
						also be posted in such areas or sections of this Site, and together
						with these Terms And Conditions, govern your access and usage of the
						same. These Terms And Conditions, together with such applicable
						additional terms and conditions, are referred to as this
						“Agreement”.
						<br />
						<br />
						We reserve the right to modify or change this Agreement at any time
						as we deem appropriate and posting the modified version to the Site
						or such other method of notification as we may prescribe from time
						to time, which you agree shall be sufficient notice for the purpose
						of this section. Such modified version of the Agreement shall
						automatically be effective upon posting to the Site. Your access of
						the Site and usage of the Service following any such modification or
						change constitutes your agreement to abide and be legally bound by
						the Agreement as so modified or changed. You are therefore advised
						to review the Agreement regularly to ensure you are aware of any
						changes. Any breach shall result in the immediate and automatic
						termination of all rights and licence granted hereunder.
					</Paragraph>
					<Topic>Use of Site</Topic>
					<Paragraph>
						You may use (and, in specified instances where applicable, download)
						the Service, the Site, and the information, writings, images,
						photos, graphics, video, audio, data, text, software and/or other
						works that you see, hear or otherwise experience on the Site in the
						manner we have permitted or intended (singly or collectively, the
						“Content”) solely for your non-commercial and personal purposes
						and/or to learn about us, and our products and services.
						<br />
						<br />
						You may not appropriate, alter, broadcast, copy, derive another work
						from, disseminate, distribute, download, make available to the
						public, modify, post, reformat, reproduce, republish, transmit,
						display, imitate, or otherwise use the Content in any other way, in
						whole or in part, for monetary benefit or any other consideration,
						for commercial purposes or otherwise, except as expressly permitted
						by these Terms And Conditions and/or with our express prior written
						consent.
					</Paragraph>
					<Topic>Your Conduct</Topic>
					<Paragraph>
						You agree to use the Site, the Service, and the Content only for
						lawful purposes and in the manner permitted by and subject always to
						these Terms And Conditions. You agree not to take any action that
						might compromise the security of the Site, render the Site
						inaccessible to others or otherwise cause damage to the Site, the
						Service or the Content. You agree not to add to, subtract from, or
						otherwise modify the Content, or to attempt to access any Content
						that is not intended for you. You agree not to use the Site, the
						Service, or the Content in any manner that might interfere with the
						rights of third parties.
					</Paragraph>
					<Topic>Linking to this Site</Topic>
					<Paragraph>
						You are granted a limited, revocable, non-transferable and
						non-exclusive right to create a hyperlink to our home page so long
						as you do not portray the Site, the Service, the Content, or us in a
						false, defamatory, derogatory or offensive manner. However, unless
						you are specifically permitted by us in writing to do so, you are
						not to mirror, frame or utilise framing techniques on this Site, the
						Service or the Content, or connect “deep links” to the Site (i.e.
						create links to the Site that bypass the home page or other parts of
						the Site). This limited right may be revoked at any time in our sole
						discretion.
					</Paragraph>
					<Topic>Ownership and Intellectual Property</Topic>
					<Paragraph>
						The Site and Service contains a number of names, images, logos,
						trademarks, service marks, and/or taglines identifying us and our
						range of products and services, and third parties and their products
						and services. These are our proprietary intellectual property or the
						proprietary intellectual property of third parties who have
						authorised their use on the Site and the Service. You agree not to
						remove, change or obscure any copyright, trademark, service mark, or
						other proprietary rights notices incorporated in or accompanying the
						Site, Service and/or Content. Nothing contained in these Terms of
						Use shall be construed as conferring by implication, estoppel or
						otherwise, any licence or right to use any of our intellectual
						property or that of any third party.
					</Paragraph>
					<Topic>Indemnity</Topic>
					<Paragraph>
						You are granted a limited, revocable, non-transferable and
						non-exclusive right to create a hyperlink to our home page so long
						as you do not portray the Site, the Service, the Content, or us in a
						false, defamatory, derogatory or offensive manner. However, unless
						you are specifically permitted by us in writing to do so, you are
						not to mirror, frame or utilise framing techniques on this Site, the
						Service or the Content, or connect “deep links” to the Site (i.e.
						create links to the Site that bypass the home page or other parts of
						the Site). This limited right may be revoked at any time in our sole
						discretion.
					</Paragraph>
					<Topic>Entire Agreement / No Waiver</Topic>
					<Paragraph>
						Save as expressly provided hereunder, these Terms And Conditions
						constitute the entire agreement with respect to the subject matter
						hereof. No waiver (whether effected by a positive waiver or by a
						delay or failure in enforcement) by us of any breach or default
						hereunder shall be deemed to be a waiver of any preceding or
						subsequent breach or default.
					</Paragraph>
				</Body>
			</Container>
		</TermsAndCondition>
	)
}

export default Terms
