import { useState, useEffect, forwardRef, useRef } from 'react'
import Modal from './Modal'
import './index.scss'
import SidegroundImg from './SidegroundImg'
import { useLocation } from 'react-router'

const Sideground = forwardRef(
	(
		{
			isClickSmNav,
			setIsClickSmNav,
			navigation,
			activePath,
			turnToWhite,
			setTurnToWhite,
			touchEvent,
			movementX,
			setMovementX,
			hidden,
			socials,
			isMobile,
			setIsClickNewPage,
		},
		ref
	) => {
		const [touchEnd, setTouchEnd] = useState(false)
		const [isDraggedMe, setIsDraggedMe] = useState((state) => {
			const savedData = localStorage.getItem('dragged')
			if (savedData) {
				const now = new Date().getTime()
				const isExipred = now - savedData / (1000 * 60 * 60 * 24) >= 7
				return isExipred
			} else {
				return false
			}
		})
		const dragForFirstTime = useRef(!isDraggedMe)

		const isBreakPoint = useRef(false)
		// Set movement of client
		useEffect(() => {
			const touchEvents =
				touchEvent === 'click'
					? ['mousedown', 'mousemove', 'mouseup']
					: ['touchstart', 'touchmove', 'touchend']
			const sideground = ref.current

			const touchstart = (e) => {
				e.stopImmediatePropagation()
				const modal = document.querySelector('.navigate-modal')
				const container = document.querySelector('.navigate-modal .container')
				setTouchEnd(false)
				let left
				const oldX =
					touchEvent === 'click' ? e.clientX : e.changedTouches[0].clientX
				if (
					(e.target === modal || e.target === container) &&
					isBreakPoint.current
				) {
					left = Number(getComputedStyle(sideground).left.replace('px', ''))
				}
				const touchmove = (e) => {
					e.preventDefault()
					e.stopImmediatePropagation()
					console.log(oldX)
					const newX =
						touchEvent === 'click' ? e.clientX : e.changedTouches[0].clientX
					if (oldX < newX) {
						sideground.style.transition = '0s'
						setMovementX(`${newX - oldX}px`)
					} else if (oldX > newX) {
						sideground.style.transition = '0s'
						setMovementX(`${left - (oldX - newX)}px`)
					}
				}
				const touchend = () => {
					sideground.style.transition = '0.5s ease-in-out'
					setTouchEnd(true)
					window.removeEventListener(touchEvents[1], touchmove, {
						passive: false,
					})
					window.removeEventListener(touchEvents[2], touchend)
				}
				window.addEventListener(touchEvents[1], touchmove, { passive: false })
				window.addEventListener(touchEvents[2], touchend)
			}

			sideground.addEventListener(touchEvents[0], touchstart)

			return () => {
				sideground.removeEventListener(touchEvents[0], touchstart)
			}
		}, [touchEvent, setMovementX, movementX, ref])

		// Decide the breakpoint
		useEffect(() => {
			const parseMovementX = Number(movementX.replace('px', ''))
			if (touchEnd) {
				if (!isBreakPoint.current) {
					if (parseMovementX > window.innerWidth / 4) {
						isBreakPoint.current = true
						setMovementX('100%')
					} else if (parseMovementX < window.innerWidth / 4) {
						isBreakPoint.current = false
						setMovementX('0')
					}
				} else {
					if (parseMovementX > window.innerWidth * (3 / 4)) {
						isBreakPoint.current = true
						setMovementX('100%')
					} else if (parseMovementX < window.innerWidth * (3 / 4)) {
						isBreakPoint.current = false
						setMovementX('0')
					}
				}
			}
		}, [touchEnd, movementX, setMovementX])

		// Attraction
		useEffect(() => {
			if (movementX && dragForFirstTime.current) {
				setIsDraggedMe((state) => {
					localStorage.setItem('dragged', new Date().getTime())
					return true
				})
			}
		}, [movementX])
		return (
			<>
				<div
					className={'sideground'}
					ref={ref}
					style={{
						left: `${hidden ? '-100%' : movementX}`,
					}}
				>
					<Modal
						navigation={navigation}
						activePath={activePath}
						setMovementX={setMovementX}
						touchEvent={touchEvent}
						socials={socials}
						setIsClickNewPage={setIsClickNewPage}
					/>
					<SidegroundImg
						isMobile={isMobile}
						isDraggedMe={isDraggedMe}
						turnToWhite={turnToWhite}
						className={`sideground-img${turnToWhite ? ' white' : ' blue'}`}
					/>
				</div>
			</>
		)
	}
)
export default Sideground
