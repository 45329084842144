import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Animated } from 'react-animated-css'
import { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../assets/vw-logo-white.svg'
import './index.scss'
import Menu from './Menu'

export default function Nav({
	navigation,
	sectionIndex,
	activePath,
	setIsClickNewPage,
}) {
	const [isScrollDown, setIsScrollDown] = useState(false)
	const nav = useRef()

	useEffect(() => {
		if (sectionIndex) {
			setIsScrollDown(true)
		} else {
			setIsScrollDown(false)
		}
	}, [sectionIndex])

	const firstTime = useRef(true)
	useEffect(() => {
		const click = (e) => {
			e.stopPropagation()
			setIsScrollDown(false)
			const unclick = (e) => {
				e.stopPropagation()
				if (!nav.current.contains(e.target)) {
					setIsScrollDown(true)
					window.removeEventListener('click', unclick)
				}
			}
			window.addEventListener('click', unclick)
		}

		if (isScrollDown && firstTime.current) {
			nav.current.addEventListener('click', click)
			firstTime.current = false
		}
	}, [isScrollDown])
	return (
		<div className="bar">
			<div
				ref={nav}
				className={`nav${isScrollDown ? ' animated' : ''}`}
				style={isScrollDown ? { transform: 'translateX(30px)' } : null}
			>
				<div className="nav-items">
					{!isScrollDown
						? navigation.map((item, key) => {
								let isActive = activePath === item.href

								return (
									<Animated
										animationIn="bounceIn"
										animationOut="bounceOut"
										animationInDelay={500}
										animationOutDelay={0}
										isVisible={isScrollDown ? false : true}
										key={`side-nav${key}`}
									>
										<Menu
											item={item}
											isActive={isActive}
											setIsClickNewPage={setIsClickNewPage}
										/>
									</Animated>
								)
						  })
						: navigation
								.filter((item) => item.href === activePath)
								.map((item) => (
									<Animated
										animationIn="bounceIn"
										animationOut="bounceOut"
										animationInDelay={500}
										animationOutDelay={500}
										isVisible={isScrollDown ? true : false}
										key={'nav-active'}
									>
										<div className={`nav-hide`}>
											<FontAwesomeIcon icon={item.icon} />
										</div>
									</Animated>
								))}
				</div>
			</div>
		</div>
	)
}
